import { useAppStateStore } from '~/store/appState'
import { useWishStore } from '~/store/wish'
import { useEventStore } from '~/store/event'
import { realtimeDatabase } from '~/service/firebase_config'
import { ref, set, update, remove, onDisconnect, increment } from 'firebase/database'
import { ref as refState} from 'vue'
import dayjs from 'dayjs'
import _debounce from 'lodash/debounce.js'

const trackingAnalytic = () => {
  const route = useRoute()
  const appStateStore = useAppStateStore()
  const wishStore = useWishStore()
  const eventStore = useEventStore()
  const hasRun = refState(false)
  let state = reactive({
    refDateAnalytic: '',
    refMonthAnalytic: '',

  })

  watch(() => route.path, () => {

    // update only thankyou page
    if(route.path === '/thankyou' && hasRun.value === false){ 
      hasRun.value = true
      updateDateAnalytic()
      updateMonthAnalytic()
    }
  })

  const updateDateAnalytic = () => {
    const currentMonth = dayjs().format('YYYY-MM-DD')
    state.refDateAnalytic = `analytic/date/${currentMonth}/${eventStore.eventId}`

    let updates:any = {}
    updates[state.refDateAnalytic] = increment(1)
    return update(ref(realtimeDatabase), updates)
  }


  const updateMonthAnalytic = () => {
    const currentMonth = dayjs().format('YYYY-MM')
    state.refMonthAnalytic = `analytic/events/${currentMonth}/${eventStore.eventId}`

    let updates:any = {}
    updates[state.refMonthAnalytic] = increment(1)
    return update(ref(realtimeDatabase), updates)
  }

  return {
    updateDateAnalytic,
    updateMonthAnalytic
  }

}

export default trackingAnalytic