import _includes from 'lodash/includes.js';
import _find from 'lodash/find.js';
import { useWishStore } from '~~/store/wish';
import { useUploadStore } from '~~/store/upload';
import { useTemplateStore } from '~~/store/template';

export default defineNuxtPlugin((nuxtApp) => {

    let wishStore = useWishStore()
    let uploadStore = useUploadStore()
    let templateStore = useTemplateStore()

    const variant = computed(() => templateStore.variant || 'w=500')

    // user upload image
    watch(() => wishStore.wishData, async (data) => {
      if(uploadStore.isPrefetchRemoveBg) {
        console.log('prefetch guest image remove bg already')
        return 
      }

      if(data && data?.guest?.image?.cfRemovebgId) {
        const imageId = data?.guest?.image?.cfRemovebgId
        const url = useCloudFlareId(imageId, variant.value)
        await templateStore.prefetchImage(url)
        uploadStore.isPrefetchRemoveBg = true
      }
    })
})