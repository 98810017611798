
import { defineStore } from 'pinia'
import { db } from '~/service/firebase_config'
import { doc, getDoc, setDoc, updateDoc, deleteDoc, onSnapshot  } from 'firebase/firestore'

import _ from 'lodash'
import Swal from 'sweetalert2'
import { useEventStore } from './event'

export const useAppVersionStore = defineStore({
  id: 'appVersion',
  state: () => {
    return {
      isChangeVersion : false,
      versionData: {
        version: '',
        timestamp: '',
        name: ''
      },
    }
  },
  actions: {
    async main() {
      await this.init()
      this.watchAppVersion()
    },

    async init() {
      const isLocalHost = _.includes(window.location.href, 'localhost')
      if(isLocalHost) {
        await this.realtimeFetchAppVersion('test')
      }

      const isTest = _.includes(window.location.href, 'wish-v2-test')
      if(isTest) {
        await this.realtimeFetchAppVersion('test')
      }

      const isProd = _.includes(window.location.href, 'wish-v2.photowish.com')
      if(isProd) {
        await this.realtimeFetchAppVersion('production')
      }

      const isBeta = _.includes(window.location.href, 'wish-v2b.photowish.com')
      if(isBeta) {
        await this.realtimeFetchAppVersion('beta')
      }
    },

    realtimeFetchAppVersion(docId: string) {
      const p = new Promise(async resolve => {

        const docRef = doc(db, "app_version", docId);
    
        onSnapshot(docRef, (doc) => {
          if (!doc.exists()) {
            this.versionData = {
              version: 'abc12345',
              timestamp: new Date().toISOString(),
              name: 'mock'
            }
            return 
          }

          const data: any = Object.assign({}, doc.data(), {
            docId: doc.id
          })

          this.versionData = {
            version: data?.version,
            timestamp: data?.timestamp,
            name: data?.docId
          }
          resolve(true)
        })
      })

      return p
    },

    watchAppVersion() {
      const eventStore = useEventStore()
      watch(() => this.versionData, async (newVal, oldVal) => {
        if(checkIsNotFoundOrClosePage()) {
          return 
        }

        if(oldVal !== newVal) {
          this.isChangeVersion = true
          const isConfirm = await Swal.fire({
            icon: "warning",
            title: "New Version !",
            html: `
              <p>มีการอัพเดตเวอร์ชั่นใหม่</p>
              <p>กรุณากดรีโหลดเพื่อเริ่มต้นใช้งานใหม่อีกครั้ง</p>
            `,
            showCancelButton: false,
            confirmButtonText: "Reload",
            confirmButtonColor: "#ff8c00",
            footer: '<p class="text-xs">เพื่อให้การใช้งานมีประสิทธิภาพมากยิ่งขึ้น</p>',
            allowOutsideClick: false,
          });

          if(isConfirm) {
            window.location.href = window.location.origin + `?eventId=${eventStore?.eventId}`
          }
          return
        }
      }, { deep: true })
    }
  },
  getters: {}
})


function checkIsNotFoundOrClosePage() {
  const isEventNotFoundPage = _.includes(window.location.href, '/eventNotFound') 
  if(isEventNotFoundPage) {
    return true
  }

  const isQrcodetNotFoundPage = _.includes(window.location.href, '/qrcodeNotFound') 
  if(isQrcodetNotFoundPage) {
    return true
  }

  const isClosePage = _.includes(window.location.href, '/close') 
  if(isClosePage) {
    return true
  }

  return false
}