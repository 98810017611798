import { useEventStore } from "~~/store/event";
import _includes from "lodash/includes.js";
export default defineNuxtPlugin((nuxtApp) => {
  const eventStore = useEventStore();

  eventStore.site = "production";
  if (_includes(window.location.href, "wish-v2b")) {
    eventStore.site = "beta";
  }

  if (_includes(window.location.href, "wish-v2-test")) {
    eventStore.site = "test";
  }

  if (_includes(window.location.href, "localhost")) {
    eventStore.site = "local";
  }
});
