import { useIdle } from '@vueuse/core'
import dayjs from 'dayjs'

export default defineNuxtPlugin(async (nuxtApp) => {
   

  const { idle } = useIdle(15 * 60 * 1000) // 15 min

  console.log('Start Time: ', dayjs().format('YYYY-MM-DD HH:mm:ss'))

  watch(() => idle.value, (idleValue: boolean) => {
    // User has been inactive for more than 10 minutes. 
    if (idleValue) {
      setTimeout(() => {
        console.log('reload')
        window.location.href = window.location.origin
      }, 1000);
    }
  })

})