const mockWishList = [
  { id: "1", lang: "both", text: "ขอให้มีความสุขมาก ๆ รักกันนานๆ" }, 
  { id: "2", lang: "both", text: "ขอให้รักกันจนแก่เฒ่า ถือไม้เท้ายอดทองกระบองยอดเพชร" }, 
  { id: "3", lang: "both", text: "ขอให้มีครอบครัวที่น่ารักและอบอุ่นตลอดไป" }, 
  { id: "4", lang: "both", text: "ขอเป็นครอบครัวที่มั่งคั่งร่ำรวย เฮง ๆ ประสบความสำเร็จ" }, 
  { id: "5", lang: "both", text: "ขอให้รักกันหวานชื่นตลอดไป มีลูกไว ๆ ทันใช้" }, 
  { id: "6", lang: "both", text: "เพื่อนเป็นฝั่งเป็นฝาแล้ว ขอให้มีครอบครัวที่ดีนะ" }, 
  { id: "7", lang: "both", text: "ขอให้วันนี้เป็นจุดเริ่มต้นของการเดินทางที่ดี และเต็มไปด้วยความรักนะ" }, 
  { id: "8", lang: "both", text: "เธอสองคนเป็นคู่ที่น่ารักมาเสมอ และขอให้เป็นคู่รักที่น่ารักตลอดไป" }, 
  { id: "9", lang: "both", text: "งานวันนี้หวานมากๆ เช่นเดียวกับความรัก ขอให้ความรักของทั้งสองหวานแบบนี้ไปเรื่อย ๆ นะ" }, 
  { id: "10", lang: "both", text: "ขอให้เพื่อนรักกันหวานชื่น เหมือนแรกรักนะ" }, 
  { id: "11", lang: "both", text: "ขอให้รักกันนาน ๆ มีอะไรไม่เข้าใจกันก็หันหน้าคุยกัน ให้อภัยกันและกันนะ" }, 
  { id: "12", lang: "both", text: "ขอให้อยู่กันด้วยความรักความเข้าใจ รักษาชีวิตคู่ให้มีความสุขตลอดไปนะ" }, 
  { id: "13", lang: "both", text: "อุปสรรคในอนาคตเป็นบททดสอบ ขอให้จับมือกันและฝ่าฟันได้ทุกอุปสรรคนะ" }, 
  { id: "14", lang: "both", text: "ขอให้มีความสุขมากๆ สู้ๆกับการปรับตัวเวลาอยู่ด้วยกัน มีอะไรก็คิดถึงวันแรกที่รักกันไว้นะ" }, 
  { id: "15", lang: "both", text: "ขอแสดงความยินดีด้วยครับ มีความสุขมากๆกับชีวิตคู่" }, 
  { id: "16", lang: "both", text: "ความรักเกิดขึ้นแล้ว สวยงามเสมอ.. ยินดีกับความอบอุ่นในวันนี้ของเพื่อนนะ" }, 
  { id: "17", lang: "both", text: "ยินดีและภูมิใจกับเจ้าบ่าวเจ้าสาวมากๆ เป็นคู่ที่เหมาะสมกันที่สุด ขอให้มีความสุขมากๆ นะ" }, 
  { id: "18", lang: "both", text: "ขอให้เจ้าบ่าวเจ้าสาวทั้ง 2 ประสบความสุข ความสำเร็จในชีวิตสมรส ชีวิตครอบครัว และชีวิตการงานตลอดไป" }, 
  { id: "19", lang: "both", text: "ขอให้มีชีวิตครอบครัวที่มั่นคง สมบูรณ์และเจริญก้าวหน้า" }, 
  { id: "20", lang: "both", text: "ยินดีกับเจ้าบ่าวเจ้าสาว ในวันที่แสนพิเศษนี้ด้วยค่ะ ขอให้ทุกวันทุกคืนมีแต่ความสุข เดินเคียงกันบนเส้นทางต่อจากนี้ไปด้วยความรัก ความเข้าใจตลอดไป" }, 
  { id: "21", lang: "both", text: "ขอให้เจ้าบ่าวเจ้าสาว มีความสุขมากๆนะ และอยู่ด้วยกันตลอดไป" }, { id: "22", lang: "both", text: "รักกันยืนยาว มีความสุขมากๆครับ" }, 
  { id: "23", lang: "both", text: "ยินดีกับเจ้าบ่าว เจ้าสาวด้วยนะครับ หากพบเจออุปสรรค เรื่องร้ายหรือเรื่องดีๆ ก็ขอให้ทั้งสอง จับมือกันแน่นๆ และเดินผ่านมันไปด้วยกันนะ" }, 
  { id: "24", lang: "both", text: "ขอให้หลานทั้งสอง ครองรักกันนานๆ ประสบความสำเร็จชีวิตครอบครัวและการงาน มีหลานไวๆนะ" }, 
  { id: "25", lang: "both", text: "ขอให้ทั้งสองครองรักร่วมกันด้วยความเข้าใจ รู้จักให้อภัย และเป็นกำลังใจซึ่งกันและกันสม่ำเสมอ เมื่อมีสามข้อนี้แล้ว ทั้งสองคนจะอยู่ที่ไหนก็มีความสุข มั่นคง ยั่งยืนตลอดไป" }, 
  { id: "26", lang: "both", text: "ขอให้มีความสุขในการครองเรือน และใช้สติในการแก้ปัญหา" }, 
  { id: "27", lang: "both", text: "ขอให้คิดถึง วันเวลาที่เพาะบ่ม….ก่อนจะมาถึง…วันนี้ แล้วจะรักกันมากยิ่งขึ้น" }, 
  { id: "28", lang: "both", text: "สุขสันต์วันแต่งงาน ขอให้เดินเคียงกันบนเส้นทางต่อจากนี้ ด้วยความรัก ความเข้าใจ สร้างชีวิตคู่ให้มีความสุขสดใสนะ" }, 
  { id: "29", lang: "both", text: "ขอให้เจ้าบ่าวดูแลเจ้าสาวให้ดีๆนะคะ หนักนิดเบาหน่อยขอให้อภัยให้กัน ขอให้ทั้งคู่ประสบความสำเร็จในชีวิต ขอให้รักกันนานๆ" }, 
  { id: "30", lang: "both", text: "ขอให้ชีวิตคู่ของคุณนั้นสุดพิเศษ คอยเกื้อหนุนค้ำจุนกันไปตลอด หนักนิดเบาหน่อยขอให้อภัยกันนะ" }, 
  { id: "31", lang: "both", text: "ขอให้ความรักที่แสนพิเศษ อันเป็นของเธอทั้งสอง นำมาซึ่งความเบิกบานยินดีเสมอไป และตลอดกาลขอแสดงความยินดีในวันแต่งงานของทั้งสองคน" }, 
  { id: "32", lang: "both", text: "เนื่องในวันสำคัญของคู่บ่าวสาวในวันนี้ ซึ่งเป็นวันสำคัญที่สุด ขออวยพรให้มีความสุข รักกันยาวนาน ผิดถูกก็ให้อภัยกัน และรักยืนยงตลอดไป" }, 
  { id: "33", lang: "both", text: "ดีใจด้วยนะ ในที่สุดวันนี้ของเธอก็มาถึงซะที ต่อจากนี้ไปขอให้รักกันนานๆ สร้างครอบครัวที่อบอุ่น มีลูกน่ารักๆ นะ" }, 
  { id: "34", lang: "both", text: "แม้ว่าวันแต่งงานของคุณมาถึงแล้วก็ผ่านไป แต่ก็ขอให้ความรักของคุณนั้นเติบโตไปตราบนานเท่านาน" }, 
  { id: "35", lang: "both", text: "ขอให้การใช้ชีวิตร่วมกันของคุณทั้งสองนั้น นำพาพวกคุณไปสู่ความสุขอันล้นเปี่ยมเกินกว่าที่คุณนั้นได้จินตนาการเอาไว้" }, 
  { id: "36", lang: "both", text: "ในทุกๆวันขอให้คุณมีแต่สิ่งสวยงามเข้ามาในชีวิตและขอให้คุณดูแลซึ่งกันและกันเหมือนวันนี้ เพื่ออนาคตการใช้ชีวิตร่วมกันของเจ้าสาวและเจ้าบ่าว มีความสุขตลอดไป" }, 
  { id: "37", lang: "both", text: "วันนี้เป็นวันเริ่มต้นชีวิตคู่ หนักนิดเบาหน่อยก็ให้อภัยกัน เพราะต่างก็มาจากคนละครอบครัว มีเรื่องให้ต้องเรียนรู้กันทุกวัน การรู้จักให้อภัย และขอโทษ จะทำให้ชีวิตคู่เดินหน้าไปได้" }, 
  { id: "38", lang: "both", text: "การแต่งงานไม่ใช่การครอบครองชีวิตของอีกฝ่ายนึง แต่เป็นการแชร์พื้นที่การใช้ชีวิตร่วมกัน และที่สำคัญเรายังคงจำเป็นที่จะต้องมีพื้นที่ส่วนตัวให้กันและกันด้วย" }, 
  { id: "39", lang: "both", text: "เคล็ดลับของการใช้ชีวิตคู่ให้มีความสุขคือ ทำทุกวันให้เหมือนวันแรกที่เราคบกัน" }, 
  { id: "40", lang: "both", text: "การที่ทั้งสองคนจะนำพาชีวิตคู่ให้ไปได้ตลอดรอดฝั่งนั้น บางครั้งการใช้ความรักเพียงอย่างเดียวอาจไม่เพียงพอ เราต้องอาศัยความเข้าใจ และเชื่อใจด้วย" }, 
  { id: "41", lang: "both", text: "สร้างบ้านต้องล้อมด้วยไม้ แต่ถ้าสร้างครอบครัวต้องล้อมด้วยความรักนะ ยินดีด้วยจ้า" }, 
  { id: "42", lang: "both", text: "ไม่มีคำสัญญา...มีแต่ความซื่อสัตย์เท่านี้ ความรักของทั้งคู่..ก็จะเติบโตอย่างงดงาม ขอให้มีความสุขมากๆนะ" }, 
  { id: "43", lang: "both", text: "ก่อนจะถึงวันนี้คู่บ่าว-สาวใช้เวลาในการศึกษาเรียนรู้แต่ละคนจนพอสมควรแล้ว ขอให้คู่บ่าวสาวนึกถึงวันนี้ และดูแลซึ่งกันและกันอย่างเข้าใจ ขอให้คู่-บ่าวสาวมีความสุขในชีวิตสมรส" }, 
  { id: "44", lang: "en", text: "Congratulations on your wedding" }, 
  { id: "45", lang: "en", text: "Wishing you two lots of love and happiness" }, 
  { id: "46", lang: "en", text: "Lots of love today and beyond" }, 
  { id: "47", lang: "en", text: "Wishing you a long and happy marriage" }, 
  { id: "48", lang: "en", text: "May you two live forever in love, and love live forever in you two" },
  { id: "49", lang: "en", text: "Wishing you a lifetime of love, laughter, and happiness together." },
  { id: "50", lang: "en", text: "Congratulations on your wedding! May your love grow stronger with each passing year."},
  { id: "51", lang: "en", text: "May your marriage be filled with endless joy and cherished memories."},
  { id: "52", lang: "en", text: "May your journey as husband and wife be as amazing as your love story."},
  { id: "53", lang: "en", text: "Congratulations on your special day! May today be the start of a wonderful forever."},
  { id: "54", lang: "en", text: "Wishing you a life filled with love, trust, and endless happiness."},
  { id: "55", lang: "en", text: "Here's to a lifetime of making each other's dreams come true."},
]

export default mockWishList
