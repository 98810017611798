<template>
  <div id="splash-screen-page" class="h-dvh">
    <div
      class="absolute text-center bottom-8 left-0 w-full text-sm text-gray-500"
    >
      www.photowish.com  
    </div>
    <div
      class="absolute text-center bottom-4 left-0 w-full text-xs text-gray-500"
    >
      v{{ version }}  {{ eventDataCompute }}  {{ eventIdLocalStorage }}
    </div>
    <!-- <div
      class="absolute text-center bottom-4 left-0 w-full text-xs text-gray-500"
    >
      {{ windowPathname }}  - {{ eventIdLocalStorage }} -
      {{ lineBrowserVersion }}
    </div> -->

    <div class="h-screen bg-white">
      <div class="h-full w-full flex flex-col justify-center items-center">
        <div class="flex-shrink-0 grow-0 flex justify-center items-center">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 289.93 177.23"
            style="width: 200px; height: 200px"
          >
            <g data-name="Layer 1">
              <g>
                <rect
                  x="96.54"
                  y="120.84"
                  width="3.01"
                  height="50.78"
                  :fill="logoColor"
                  :stroke="logoColor"
                  stroke-miterlimit="10"
                  stroke-width="0.5"
                />
                <polygon
                  points="211.42 70.35 214.43 70.35 214.43 21.14 229.76 21.14 229.76 19.5 214.43 19.5 214.43 4.79 211.42 4.79 211.42 19.5 196.09 19.5 196.09 21.14 211.42 21.14 211.42 70.35"
                  :fill="logoColor"
                  :stroke="logoColor"
                  stroke-miterlimit="10"
                  stroke-width="0.5"
                />
                <path
                  d="M167.5,19.57A25.39,25.39,0,1,0,192.9,45,25.39,25.39,0,0,0,167.5,19.57Zm.18,49.89c-12.32,0-22.31-11-22.31-24.57s10-24.57,22.31-24.57S190,31.32,190,44.89,180,69.46,167.68,69.46Z"
                  :fill="logoColor"
                  :stroke="logoColor"
                  stroke-miterlimit="10"
                  stroke-width="0.5"
                />
                <path
                  d="M261.14,19.57A25.39,25.39,0,1,0,286.53,45,25.39,25.39,0,0,0,261.14,19.57Zm.18,49.89C249,69.46,239,58.46,239,44.89s10-24.57,22.31-24.57,22.31,11,22.31,24.57S273.64,69.46,261.32,69.46Z"
                  :fill="logoColor"
                  :stroke="logoColor"
                  stroke-miterlimit="10"
                  stroke-width="0.5"
                />
                <path
                  d="M125.74,70.35h2.33V43.8h0A25.36,25.36,0,0,0,80.35,33V4.78h-3V70.35h3V39.19c2.41-10.82,11.51-18.87,22.38-18.87,12.57,0,22.77,10.74,23,24.09Z"
                  :fill="logoColor"
                  :stroke="logoColor"
                  stroke-miterlimit="10"
                  stroke-width="0.5"
                />
                <path
                  d="M215.25,120.84a25.34,25.34,0,0,0-22.35,13.39V106.06h-3v65.57h3V140.46c2.41-10.81,11.5-18.86,22.38-18.86,12.57,0,22.76,10.74,23,24.08v25.95h2.32V145.07h0A25.38,25.38,0,0,0,215.25,120.84Z"
                  :fill="logoColor"
                  :stroke="logoColor"
                  stroke-miterlimit="10"
                  stroke-width="0.5"
                />
                <path
                  d="M144.89,145.79c-11.71.31-20.92-1.62-21.36-9.35-.45-8,8.24-15.13,21.36-15,13.85.11,18.86,9,21.09,15.63h3.66c-2.44-6.81-9.08-16.44-24.75-16.44-9.31,0-26.39,2.42-25.1,16.12.53,5.58,5.66,10.88,25.1,10.32,22-.63,22.45,7.89,21.27,12.38-2,7.71-9.5,11.69-20.81,11.26s-18.34-5.85-21.9-12.17h-3.84c4.94,9.71,15.88,12.59,25.1,13.05,18.25.89,24.93-6.66,25.46-13.16C171.07,147.4,160.4,145.37,144.89,145.79Z"
                  :fill="logoColor"
                  :stroke="logoColor"
                  stroke-miterlimit="10"
                  stroke-width="0.5"
                />
                <polygon
                  points="272.17 94.64 271.11 93.58 262.65 102.04 262.65 90.52 259.63 90.52 259.63 102.04 251.17 93.58 250.11 94.64 259.32 103.86 246.29 103.86 246.29 106.87 257.82 106.87 250.11 114.58 251.17 115.64 259.63 107.18 259.63 120.21 262.65 120.21 262.65 107.18 271.11 115.64 272.17 114.58 264.46 106.87 275.99 106.87 275.99 103.86 262.96 103.86 272.17 94.64"
                  :fill="logoColor"
                  :stroke="logoColor"
                  stroke-miterlimit="10"
                  stroke-width="0.5"
                />
                <polygon
                  points="59.94 170.14 43.28 134.1 42.38 134.1 42.35 134.18 24.38 170.1 10 120.84 6.99 120.84 21.82 171.63 23.62 171.63 24.83 171.63 25.42 171.63 42.04 138.4 57.64 171.63 59.5 171.63 60.63 171.63 62.52 171.63 77.34 120.84 74.33 120.84 59.94 170.14"
                  :fill="logoColor"
                  :stroke="logoColor"
                  stroke-miterlimit="10"
                  stroke-width="0.5"
                />
                <path
                  d="M10,50.61a25.43,25.43,0,1,0,0-11.37V19.5H7V98.76h3ZM33.6,20.9a23.79,23.79,0,0,1,23.55,24,23.78,23.78,0,0,1-23.55,24,23.78,23.78,0,0,1-23.55-24A23.79,23.79,0,0,1,33.6,20.9Z"
                  :fill="logoColor"
                  :stroke="logoColor"
                  stroke-miterlimit="10"
                  stroke-width="0.5"
                />
              </g>
            </g>
          </svg>
        </div>

        <p class="mb-10 mt-3 tracking-widest text-gray-600 text-center">
          เขียนคำอวยพรด้วยรูป
        </p>
        <!-- <p class="mb-10 mt-3 tracking-widest text-gray-600 text-center">{{ $t('splashScreen') }}</p> -->

        <div id="splash-screen-error" class=""></div>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import packageJson from "~/package.json";
import { useAppStateStore } from "~/store/appState";
import { useEventStore } from "~/store/event";

const appStateStore = useAppStateStore();
const eventStore = useEventStore();
const eventDataCompute = computed(() => eventStore?.eventData ?  eventStore?.eventData?.eventId: "-" );
const version = ref(packageJson.version);
const lineBrowserVersion = ref("");
const windowPathname = ref("");
const eventIdParams = ref("");
const eventIdLocalStorage = ref("");
const logoColor = ref("black");

import liff from "@line/liff";
import _ from "lodash";

onMounted(() => {
  const { userAgent } = navigator;
  let lineVersion = userAgent.split(" ");
  lineVersion = _.last(lineVersion);
  lineBrowserVersion.value = lineVersion;

  const pathname = window.location.pathname;
  windowPathname.value = pathname || "/";

  const eventIdParam = window.location.search.split("eventId=")[1];
  eventIdParams.value = eventIdParam || "params";

  const local = window.localStorage.getItem("eventId");
  eventIdLocalStorage.value = local || "localstorage";
  // alert(`userAgent -> ${userAgent}`)
});

const hasRun = ref(false);

watch(
  () => eventStore.eventData,
  () => {
    if (!hasRun.value && eventStore.eventData?.eventId) {
      hasRun.value = true;
      setTimeout(async () => {
        if (useRoute().path === "/close") {
          return;
        }
        
        appStateStore.callback = () => true;
        await appStateStore.goNext();
      }, 3000);
    }
  },
  { immediate: true }
);

onMounted(() => {
  // console.log('version ->',navigator.appVersion)
  // alert(`liff version -> ${liff.getVersion()}`)

    ;
  appStateStore.landedIndexPage = true;
});
</script>

<style scoped>
.font-serif {
  font-family: "serif";
}
</style>