<template>
  <div>
    <NuxtLayout>
      <NuxtPage />
    </NuxtLayout>
  </div>
</template>


<script lang="ts" setup>
  import { useAuthStore } from './store/auth'
  import { useEventStore } from './store/event'
  import { useAppStateStore } from './store/appState'
  import { useTemplateStore } from './store/template'
  import { useWishStore } from './store/wish'
  import { usePromptpay } from './store/promptpay'
  import { useRestorePageStore } from './store/restorePage'
  import { useAppVersionStore } from './store/appVersion'

  const $route = useRoute()
  const $router = useRouter()

  const authStore = useAuthStore()
  const appStateStore = useAppStateStore()
  const eventStore = useEventStore()
  const templateStore = useTemplateStore()
  const wishStore = useWishStore()
  const promptpayStore = usePromptpay()
  const restorePage = useRestorePageStore()
  const appVersion = useAppVersionStore()

  onMounted(async () => {
    await authStore.main()
    const isSuccess = await eventStore.main()
    if(isSuccess) {
      await Promise.all([
        wishStore.main(),
        appStateStore.main(),
        promptpayStore.fetchPromptpayReceipts(),
        //TODO: uncomment Monday
       // restorePage.getRestorePage()
      ])

      await templateStore.main()
      await appVersion.main()
    } 
  })
</script>


