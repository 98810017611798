import { useEventStore } from "~~/store/event";

export default defineNuxtPlugin((nuxtApp) => {

    const eventStore = useEventStore();


    // this is for incase  cannot open new tab
    function insertFullSizeIframe(path: string) {
        const iframe = document.createElement('iframe');
        iframe.src = `https://wish-v2.photowish.com` +path
        iframe.style.height = '100%';
        iframe.style.width = '100%';
        iframe.style.border = 'none';
        iframe.style.position = 'fixed';
            //z-index: 999999;
        iframe.style.top = '0';
        iframe.style.left = '0';
        iframe.style.right = '0';
        iframe.style.bottom = '0';
        iframe.style.zIndex = '9999999999';
        

        document.body.appendChild(iframe);
    }

    watch(()=> eventStore.eventIdNotFound, (val) => {
        if(val){
              
            window.location.href = window.location.origin + "/views/event-not-found.html?eventId=" + eventStore.eventId;
            
            insertFullSizeIframe("/views/event-not-found.html?eventId=" + eventStore.eventId);
        }
    })

    watch(()=> eventStore.qrcodeIdNotFound, (val) => {
        if(val){
              
            window.location.href = window.location.origin + "/views/qr-not-found.html?qrcodeId=" + eventStore.qrcodeId;
            insertFullSizeIframe("/views/qr-not-found.html?qrcodeId=" + eventStore.qrcodeId);
        }
    })
})
