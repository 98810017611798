import { useAuthStore } from "~~/store/auth";
import { useEventStore } from "~~/store/event";
import _includes from "lodash/includes.js";
import liff from "@line/liff";

export default defineNuxtPlugin(async (nuxtApp) => {
    
  const route = useRoute();
  const eventStore = useEventStore();

  let qrId = route.params.qrcodeId || route.query.qrId;
  if (qrId) {
    let qrCodeData = await eventStore.fetchQrcodeId(qrId);
     
    
    //ไม่พบ หรือ พบแล้วยังไม่ได้เชื่อม
    if (qrCodeData == null || !qrCodeData.eventId) {
      //remove localstoreage eventId
      window.localStorage.removeItem("eventId");
      window.localStorage.removeItem("qrcodeId");
     
      return eventStore.qrcodeIdNotFound = true;
    }
    
    if (qrCodeData?.eventId) {
      // assign query eventId to route
      return route.query.eventId = qrCodeData.eventId;
    }
    

  }
});
