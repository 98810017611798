import { useAuthStore } from "~~/store/auth";
import { useEventStore } from "~~/store/event";
import _includes from "lodash/includes.js";
import liff from "@line/liff";

export default defineNuxtPlugin(async (nuxtApp) => {
  // qrid-to-localstorage
    ;
  const route = useRoute();
  const eventStore = useEventStore();

  let eventIdParam = route.params.eventId;
  let eventIdQuery = route.query.eventId;

  // eventId from LIFF STATE
  const queryString = decodeURIComponent(window.location.search).replace(
    "?liff.state=",
    ""
  );
  const params = new URLSearchParams(queryString);
  const paramsString = params.toString();

  // eventId=xxxx&demo=xxxx
  // --- query with node: eventId ---
  const splitArr = paramsString?.split("eventId=")[1];
  const targetEventIdFromLiff = splitArr?.split("&")[0];

  //save eventId to localstorage
  let thisEventId = targetEventIdFromLiff || eventIdQuery || eventIdParam;
  if (thisEventId) {
    window.localStorage.setItem("eventId", thisEventId);
  }

  //TODO:  to be removed
  watch(
    () => eventStore.eventData,
    () => {
      window.localStorage.removeItem("isDemo");
      window.localStorage.removeItem("qrcodeId");
      const authStore = useAuthStore();
      if (eventStore.eventData && eventStore.eventType === "wedding") {
        const nodeName = `${eventStore?.eventId}:${authStore?.userId}:guestImage`;
        window.localStorage.removeItem(nodeName);
      }
    }
  );

  return;
});
