import _includes from 'lodash/includes.js';
import _find from 'lodash/find.js';
import { useTemplateStore } from '~~/store/template';

export default defineNuxtPlugin((nuxtApp) => {
    let templateStore = useTemplateStore()

    const variant = computed(() => templateStore.variant || 'w=500')
  
    // background image
    watch(() => templateStore.template_1, async (templateId: string) => {
      const templateData = _find(templateStore.templateList, t => t?.templateId === templateId)
      const imageId = templateData?.elements?.background?.cfId
      if(imageId) {

        const url = useCloudFlareId(imageId, variant.value)
        await templateStore.prefetchImage(url)
      }
    })

    watch(() => templateStore.template_2, async (templateId: string) => {
      const templateData = _find(templateStore.templateList, t => t?.templateId === templateId)
      const imageId = templateData?.elements?.background?.cfId
      if(imageId) {
        const url = useCloudFlareId(imageId, variant.value)
        await templateStore.prefetchImage(url)
      }
    })
    
    watch(() => templateStore.template_3, async (templateId: string) => {
      const templateData = _find(templateStore.templateList, t => t?.templateId === templateId)
      const imageId = templateData?.elements?.background?.cfId
      if(imageId) {
        const url = useCloudFlareId(imageId, variant.value)
        await templateStore.prefetchImage(url)
      }
    })
})