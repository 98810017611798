import { default as closemPJLAKKqJAMeta } from "/Users/utumpornnaikorn/mywork/Niceloop-project2/photowish-production-v2/photowish-guest-v2/pages/close.vue?macro=true";
import { default as editor_copyGiGe768WUZMeta } from "/Users/utumpornnaikorn/mywork/Niceloop-project2/photowish-production-v2/photowish-guest-v2/pages/editor_copy.vue?macro=true";
import { default as editorzKnHlBBsBaMeta } from "/Users/utumpornnaikorn/mywork/Niceloop-project2/photowish-production-v2/photowish-guest-v2/pages/editor.vue?macro=true";
import { default as error4AFzhtzhUrMeta } from "/Users/utumpornnaikorn/mywork/Niceloop-project2/photowish-production-v2/photowish-guest-v2/pages/error.vue?macro=true";
import { default as _91eventId_939v14BlmvfTMeta } from "/Users/utumpornnaikorn/mywork/Niceloop-project2/photowish-production-v2/photowish-guest-v2/pages/event/[eventId].vue?macro=true";
import { default as index2z1LINHK7eMeta } from "/Users/utumpornnaikorn/mywork/Niceloop-project2/photowish-production-v2/photowish-guest-v2/pages/event/index.vue?macro=true";
import { default as eventNotFoundnhoalcYtFSMeta } from "/Users/utumpornnaikorn/mywork/Niceloop-project2/photowish-production-v2/photowish-guest-v2/pages/eventNotFound.vue?macro=true";
import { default as externalsXv9MtgbQmMeta } from "/Users/utumpornnaikorn/mywork/Niceloop-project2/photowish-production-v2/photowish-guest-v2/pages/external.vue?macro=true";
import { default as index064Acc9o6CMeta } from "/Users/utumpornnaikorn/mywork/Niceloop-project2/photowish-production-v2/photowish-guest-v2/pages/index.vue?macro=true";
import { default as loginEventNndmMMcsvkMeta } from "/Users/utumpornnaikorn/mywork/Niceloop-project2/photowish-production-v2/photowish-guest-v2/pages/loginEvent.vue?macro=true";
import { default as maintenanceq9fdzvQvNGMeta } from "/Users/utumpornnaikorn/mywork/Niceloop-project2/photowish-production-v2/photowish-guest-v2/pages/maintenance.vue?macro=true";
import { default as mywishnKVjlowufZMeta } from "/Users/utumpornnaikorn/mywork/Niceloop-project2/photowish-production-v2/photowish-guest-v2/pages/mywish.vue?macro=true";
import { default as test_45iframelMkba2mgyqMeta } from "/Users/utumpornnaikorn/mywork/Niceloop-project2/photowish-production-v2/photowish-guest-v2/pages/playground/test-iframe.vue?macro=true";
import { default as test_45template1Oxq32XibtMeta } from "/Users/utumpornnaikorn/mywork/Niceloop-project2/photowish-production-v2/photowish-guest-v2/pages/playground/test-template.vue?macro=true";
import { default as test_45welcomeBqxzgvSfiPMeta } from "/Users/utumpornnaikorn/mywork/Niceloop-project2/photowish-production-v2/photowish-guest-v2/pages/playground/test-welcome.vue?macro=true";
import { default as test_45wishes_45konvarEFMPSZElgMeta } from "/Users/utumpornnaikorn/mywork/Niceloop-project2/photowish-production-v2/photowish-guest-v2/pages/playground/test-wishes-konva.vue?macro=true";
import { default as test_45wishesUJ4vXiAlXfMeta } from "/Users/utumpornnaikorn/mywork/Niceloop-project2/photowish-production-v2/photowish-guest-v2/pages/playground/test-wishes.vue?macro=true";
import { default as previewp7cU0CkgClMeta } from "/Users/utumpornnaikorn/mywork/Niceloop-project2/photowish-production-v2/photowish-guest-v2/pages/preview.vue?macro=true";
import { default as attach4XqQuetkvBMeta } from "/Users/utumpornnaikorn/mywork/Niceloop-project2/photowish-production-v2/photowish-guest-v2/pages/promptpay/attach.vue?macro=true";
import { default as index0zHslcha6nMeta } from "/Users/utumpornnaikorn/mywork/Niceloop-project2/photowish-production-v2/photowish-guest-v2/pages/promptpay/index.vue?macro=true";
import { default as _91qrcodeId_93rshAdmOEHsMeta } from "/Users/utumpornnaikorn/mywork/Niceloop-project2/photowish-production-v2/photowish-guest-v2/pages/qr/[qrcodeId].vue?macro=true";
import { default as indexBSmB8qesYFMeta } from "/Users/utumpornnaikorn/mywork/Niceloop-project2/photowish-production-v2/photowish-guest-v2/pages/qr/index.vue?macro=true";
import { default as qrcodeNotFoundVHlCGk3XYIMeta } from "/Users/utumpornnaikorn/mywork/Niceloop-project2/photowish-production-v2/photowish-guest-v2/pages/qrcodeNotFound.vue?macro=true";
import { default as selectTemplatedFXTvfpdk6Meta } from "/Users/utumpornnaikorn/mywork/Niceloop-project2/photowish-production-v2/photowish-guest-v2/pages/selectTemplate.vue?macro=true";
import { default as selectUploadAndWishStkmaTWzVjMeta } from "/Users/utumpornnaikorn/mywork/Niceloop-project2/photowish-production-v2/photowish-guest-v2/pages/selectUploadAndWish.vue?macro=true";
import { default as test_cover_image_welcomeQ4SqegTjhtMeta } from "/Users/utumpornnaikorn/mywork/Niceloop-project2/photowish-production-v2/photowish-guest-v2/pages/test_cover_image_welcome.vue?macro=true";
import { default as thankyoutGSxuoMsuRMeta } from "/Users/utumpornnaikorn/mywork/Niceloop-project2/photowish-production-v2/photowish-guest-v2/pages/thankyou.vue?macro=true";
import { default as indexWVpYl017NUMeta } from "/Users/utumpornnaikorn/mywork/Niceloop-project2/photowish-production-v2/photowish-guest-v2/pages/v2/index.vue?macro=true";
import { default as templatexeUATjjCOtMeta } from "/Users/utumpornnaikorn/mywork/Niceloop-project2/photowish-production-v2/photowish-guest-v2/pages/v2/template.vue?macro=true";
import { default as wish_45cropperbPgcfylxFVMeta } from "/Users/utumpornnaikorn/mywork/Niceloop-project2/photowish-production-v2/photowish-guest-v2/pages/v2/wish-cropper.vue?macro=true";
import { default as wishkpMCi0MMCdMeta } from "/Users/utumpornnaikorn/mywork/Niceloop-project2/photowish-production-v2/photowish-guest-v2/pages/v2/wish.vue?macro=true";
import { default as welcomeVVnTjEzWRuMeta } from "/Users/utumpornnaikorn/mywork/Niceloop-project2/photowish-production-v2/photowish-guest-v2/pages/welcome.vue?macro=true";
export default [
  {
    name: "close",
    path: "/close",
    meta: closemPJLAKKqJAMeta || {},
    component: () => import("/Users/utumpornnaikorn/mywork/Niceloop-project2/photowish-production-v2/photowish-guest-v2/pages/close.vue")
  },
  {
    name: "editor_copy",
    path: "/editor_copy",
    component: () => import("/Users/utumpornnaikorn/mywork/Niceloop-project2/photowish-production-v2/photowish-guest-v2/pages/editor_copy.vue")
  },
  {
    name: "editor",
    path: "/editor",
    component: () => import("/Users/utumpornnaikorn/mywork/Niceloop-project2/photowish-production-v2/photowish-guest-v2/pages/editor.vue")
  },
  {
    name: "error",
    path: "/error",
    meta: error4AFzhtzhUrMeta || {},
    component: () => import("/Users/utumpornnaikorn/mywork/Niceloop-project2/photowish-production-v2/photowish-guest-v2/pages/error.vue")
  },
  {
    name: "event-eventId",
    path: "/event/:eventId()",
    component: () => import("/Users/utumpornnaikorn/mywork/Niceloop-project2/photowish-production-v2/photowish-guest-v2/pages/event/[eventId].vue")
  },
  {
    name: "event",
    path: "/event",
    component: () => import("/Users/utumpornnaikorn/mywork/Niceloop-project2/photowish-production-v2/photowish-guest-v2/pages/event/index.vue")
  },
  {
    name: "eventNotFound",
    path: "/eventNotFound",
    meta: eventNotFoundnhoalcYtFSMeta || {},
    component: () => import("/Users/utumpornnaikorn/mywork/Niceloop-project2/photowish-production-v2/photowish-guest-v2/pages/eventNotFound.vue")
  },
  {
    name: "external",
    path: "/external",
    meta: externalsXv9MtgbQmMeta || {},
    component: () => import("/Users/utumpornnaikorn/mywork/Niceloop-project2/photowish-production-v2/photowish-guest-v2/pages/external.vue")
  },
  {
    name: "index",
    path: "/",
    component: () => import("/Users/utumpornnaikorn/mywork/Niceloop-project2/photowish-production-v2/photowish-guest-v2/pages/index.vue")
  },
  {
    name: "loginEvent",
    path: "/loginEvent",
    meta: loginEventNndmMMcsvkMeta || {},
    component: () => import("/Users/utumpornnaikorn/mywork/Niceloop-project2/photowish-production-v2/photowish-guest-v2/pages/loginEvent.vue")
  },
  {
    name: "maintenance",
    path: "/maintenance",
    meta: maintenanceq9fdzvQvNGMeta || {},
    component: () => import("/Users/utumpornnaikorn/mywork/Niceloop-project2/photowish-production-v2/photowish-guest-v2/pages/maintenance.vue")
  },
  {
    name: "mywish",
    path: "/mywish",
    component: () => import("/Users/utumpornnaikorn/mywork/Niceloop-project2/photowish-production-v2/photowish-guest-v2/pages/mywish.vue")
  },
  {
    name: "playground-test-iframe",
    path: "/playground/test-iframe",
    meta: test_45iframelMkba2mgyqMeta || {},
    component: () => import("/Users/utumpornnaikorn/mywork/Niceloop-project2/photowish-production-v2/photowish-guest-v2/pages/playground/test-iframe.vue")
  },
  {
    name: "playground-test-template",
    path: "/playground/test-template",
    meta: test_45template1Oxq32XibtMeta || {},
    component: () => import("/Users/utumpornnaikorn/mywork/Niceloop-project2/photowish-production-v2/photowish-guest-v2/pages/playground/test-template.vue")
  },
  {
    name: "playground-test-welcome",
    path: "/playground/test-welcome",
    meta: test_45welcomeBqxzgvSfiPMeta || {},
    component: () => import("/Users/utumpornnaikorn/mywork/Niceloop-project2/photowish-production-v2/photowish-guest-v2/pages/playground/test-welcome.vue")
  },
  {
    name: "playground-test-wishes-konva",
    path: "/playground/test-wishes-konva",
    meta: test_45wishes_45konvarEFMPSZElgMeta || {},
    component: () => import("/Users/utumpornnaikorn/mywork/Niceloop-project2/photowish-production-v2/photowish-guest-v2/pages/playground/test-wishes-konva.vue")
  },
  {
    name: "playground-test-wishes",
    path: "/playground/test-wishes",
    meta: test_45wishesUJ4vXiAlXfMeta || {},
    component: () => import("/Users/utumpornnaikorn/mywork/Niceloop-project2/photowish-production-v2/photowish-guest-v2/pages/playground/test-wishes.vue")
  },
  {
    name: "preview",
    path: "/preview",
    component: () => import("/Users/utumpornnaikorn/mywork/Niceloop-project2/photowish-production-v2/photowish-guest-v2/pages/preview.vue")
  },
  {
    name: "promptpay-attach",
    path: "/promptpay/attach",
    component: () => import("/Users/utumpornnaikorn/mywork/Niceloop-project2/photowish-production-v2/photowish-guest-v2/pages/promptpay/attach.vue")
  },
  {
    name: "promptpay",
    path: "/promptpay",
    component: () => import("/Users/utumpornnaikorn/mywork/Niceloop-project2/photowish-production-v2/photowish-guest-v2/pages/promptpay/index.vue")
  },
  {
    name: "qr-qrcodeId",
    path: "/qr/:qrcodeId()",
    component: () => import("/Users/utumpornnaikorn/mywork/Niceloop-project2/photowish-production-v2/photowish-guest-v2/pages/qr/[qrcodeId].vue")
  },
  {
    name: "qr",
    path: "/qr",
    component: () => import("/Users/utumpornnaikorn/mywork/Niceloop-project2/photowish-production-v2/photowish-guest-v2/pages/qr/index.vue")
  },
  {
    name: "qrcodeNotFound",
    path: "/qrcodeNotFound",
    meta: qrcodeNotFoundVHlCGk3XYIMeta || {},
    component: () => import("/Users/utumpornnaikorn/mywork/Niceloop-project2/photowish-production-v2/photowish-guest-v2/pages/qrcodeNotFound.vue")
  },
  {
    name: "selectTemplate",
    path: "/selectTemplate",
    component: () => import("/Users/utumpornnaikorn/mywork/Niceloop-project2/photowish-production-v2/photowish-guest-v2/pages/selectTemplate.vue")
  },
  {
    name: "selectUploadAndWish",
    path: "/selectUploadAndWish",
    component: () => import("/Users/utumpornnaikorn/mywork/Niceloop-project2/photowish-production-v2/photowish-guest-v2/pages/selectUploadAndWish.vue")
  },
  {
    name: "test_cover_image_welcome",
    path: "/test_cover_image_welcome",
    component: () => import("/Users/utumpornnaikorn/mywork/Niceloop-project2/photowish-production-v2/photowish-guest-v2/pages/test_cover_image_welcome.vue")
  },
  {
    name: "thankyou",
    path: "/thankyou",
    component: () => import("/Users/utumpornnaikorn/mywork/Niceloop-project2/photowish-production-v2/photowish-guest-v2/pages/thankyou.vue")
  },
  {
    name: "v2",
    path: "/v2",
    component: () => import("/Users/utumpornnaikorn/mywork/Niceloop-project2/photowish-production-v2/photowish-guest-v2/pages/v2/index.vue")
  },
  {
    name: "v2-template",
    path: "/v2/template",
    component: () => import("/Users/utumpornnaikorn/mywork/Niceloop-project2/photowish-production-v2/photowish-guest-v2/pages/v2/template.vue")
  },
  {
    name: "v2-wish-cropper",
    path: "/v2/wish-cropper",
    meta: wish_45cropperbPgcfylxFVMeta || {},
    component: () => import("/Users/utumpornnaikorn/mywork/Niceloop-project2/photowish-production-v2/photowish-guest-v2/pages/v2/wish-cropper.vue")
  },
  {
    name: "v2-wish",
    path: "/v2/wish",
    meta: wishkpMCi0MMCdMeta || {},
    component: () => import("/Users/utumpornnaikorn/mywork/Niceloop-project2/photowish-production-v2/photowish-guest-v2/pages/v2/wish.vue")
  },
  {
    name: "welcome",
    path: "/welcome",
    component: () => import("/Users/utumpornnaikorn/mywork/Niceloop-project2/photowish-production-v2/photowish-guest-v2/pages/welcome.vue")
  }
]