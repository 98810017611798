import { useEventStore } from "~/store/event";

import dayjs from "dayjs";
import _includes from "lodash/includes";
import liff from "@line/liff";

export default defineNuxtPlugin(async (nuxtApp) => {
  const eventStore = useEventStore();
  const eventData = computed(() => eventStore.eventData);
  const eventData_EventId = computed(() => eventData.value?.eventId);
  const hasRun = ref(false);

  // if LIFF Exit
  if (liff.isInClient()) {
    return;
  }
  watchEffect(() => {
    if (!hasRun.value && eventData.value?.eventId) {
      hasRun.value = true;
      const eventDate =
        eventData.value?.information?.date ||
        dayjs().add(7, "days").format("YYYY-MM-DD");
      const isFarEvent = checkEventDate(eventDate);
      const { userAgent } = navigator;

      let targetSite = "production";
      if (isFarEvent) {
        targetSite = "beta";
      }

      if (_includes(window.location.href, "wish-v2-test")) {
        targetSite = "test";
      }
      //is localhost
      if (window.location.href.includes("localhost")) {
        targetSite = "local";
      }

      if (!liff.isInClient() && userAgent.includes("Line")) {
        targetSite += "_liff";
      }

      switch (targetSite) {
        case "production":
        case "test":
        case "local":
          //do not thing
          return;
        case "beta":
          return pushToWebsiteBeta();

        case "production_liff":
          return openProductionLiff();
        case "beta_liff":
          return openBetaLiff();
        case "test_liff":
          return openTestLiff();

        default:
          return;
      }
    }
  });

  const checkEventDate = (eventDate: string) => {
    const now = dayjs();
    const diffDate = Math.abs(now.diff(eventDate, "day"));

    if (diffDate >= 8) {
      return true;
    } else {
      return false;
    }
  };

  const openProductionLiff = async () => {
    useRouter().push(`/close`);
    // await delay(2);
    window.location.href = `https://liff.line.me/2004940069-ApMgwb8O?eventId=${eventData_EventId.value}`;
  };

  const openBetaLiff = async () => {
    useRouter().push(`/close`);
    // await delay(2);
    window.location.href = `https://liff.line.me/2004940069-6dL72NYd?eventId=${eventData_EventId.value}`;
  };

  const openTestLiff = async () => {
    useRouter().push(`/close`);
    // await delay(2);
    window.location.href = `https://liff.line.me/2004940069-z1DopqLb?eventId=${eventData_EventId.value}`;
  };

  const pushToWebsiteBeta = async () => {
    //check is same site exit, prevent loop
    if (window.location.href.includes("wish-v2b")) {
      return;
    }

    window.location.href = `https://wish-v2b.photowish.com/?eventId=${eventData_EventId.value}`;
  };
});
