import revive_payload_client_4sVQNw7RlN from "/Users/utumpornnaikorn/mywork/Niceloop-project2/photowish-production-v2/photowish-guest-v2/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_KgADcZ0jPj from "/Users/utumpornnaikorn/mywork/Niceloop-project2/photowish-production-v2/photowish-guest-v2/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_jmwsqit4Rs from "/Users/utumpornnaikorn/mywork/Niceloop-project2/photowish-production-v2/photowish-guest-v2/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_yVLowv6hDl from "/Users/utumpornnaikorn/mywork/Niceloop-project2/photowish-production-v2/photowish-guest-v2/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_bs2bWDGRIE from "/Users/utumpornnaikorn/mywork/Niceloop-project2/photowish-production-v2/photowish-guest-v2/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_8vK7RkfGxZ from "/Users/utumpornnaikorn/mywork/Niceloop-project2/photowish-production-v2/photowish-guest-v2/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_UciE0i6zes from "/Users/utumpornnaikorn/mywork/Niceloop-project2/photowish-production-v2/photowish-guest-v2/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_YdLad5Mpq3 from "/Users/utumpornnaikorn/mywork/Niceloop-project2/photowish-production-v2/photowish-guest-v2/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.js";
import components_plugin_KR1HBZs4kY from "/Users/utumpornnaikorn/mywork/Niceloop-project2/photowish-production-v2/photowish-guest-v2/.nuxt/components.plugin.mjs";
import prefetch_client_5tzzN0oIVL from "/Users/utumpornnaikorn/mywork/Niceloop-project2/photowish-production-v2/photowish-guest-v2/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_h9wXJmkVyi from "/Users/utumpornnaikorn/mywork/Niceloop-project2/photowish-production/module-template-v1/src/runtime/plugin.ts";
import _000_breaker_client_xINncjSvz7 from "/Users/utumpornnaikorn/mywork/Niceloop-project2/photowish-production-v2/photowish-guest-v2/plugins/000.breaker.client.ts";
import _001_qrId_check_client_JGBoz9pueN from "/Users/utumpornnaikorn/mywork/Niceloop-project2/photowish-production-v2/photowish-guest-v2/plugins/001.qrId-check.client.ts";
import _002_prod_beta_liff_client_BxqcQ7QM0G from "/Users/utumpornnaikorn/mywork/Niceloop-project2/photowish-production-v2/photowish-guest-v2/plugins/002.prod-beta-liff.client.ts";
import _003_eventId_to_localstorage_client_8rkACoW05W from "/Users/utumpornnaikorn/mywork/Niceloop-project2/photowish-production-v2/photowish-guest-v2/plugins/003.eventId-to-localstorage.client.ts";
import _008_wish_site_client_n8vfv7AW6P from "/Users/utumpornnaikorn/mywork/Niceloop-project2/photowish-production-v2/photowish-guest-v2/plugins/008.wish-site.client.ts";
import _101_idle_to_reload_QQHZvxCeOh from "/Users/utumpornnaikorn/mywork/Niceloop-project2/photowish-production-v2/photowish-guest-v2/plugins/101.idle-to-reload.ts";
import i18n_VfGcjrvSkj from "/Users/utumpornnaikorn/mywork/Niceloop-project2/photowish-production-v2/photowish-guest-v2/plugins/i18n.ts";
import prefetch_templates_client_mgQLXdR3ui from "/Users/utumpornnaikorn/mywork/Niceloop-project2/photowish-production-v2/photowish-guest-v2/plugins/prefetch.templates.client.ts";
import prefetch_wish_client_vSB1aomzRh from "/Users/utumpornnaikorn/mywork/Niceloop-project2/photowish-production-v2/photowish-guest-v2/plugins/prefetch.wish.client.ts";
import sentry_client_shVUlIjFLk from "/Users/utumpornnaikorn/mywork/Niceloop-project2/photowish-production-v2/photowish-guest-v2/plugins/sentry.client.ts";
import trackingAnalytic_RY9P8l6vUU from "/Users/utumpornnaikorn/mywork/Niceloop-project2/photowish-production-v2/photowish-guest-v2/plugins/trackingAnalytic.ts";
import trackingPage_UajUVRF1fx from "/Users/utumpornnaikorn/mywork/Niceloop-project2/photowish-production-v2/photowish-guest-v2/plugins/trackingPage.ts";
export default [
  revive_payload_client_4sVQNw7RlN,
  unhead_KgADcZ0jPj,
  router_jmwsqit4Rs,
  payload_client_yVLowv6hDl,
  navigation_repaint_client_bs2bWDGRIE,
  check_outdated_build_client_8vK7RkfGxZ,
  chunk_reload_client_UciE0i6zes,
  plugin_vue3_YdLad5Mpq3,
  components_plugin_KR1HBZs4kY,
  prefetch_client_5tzzN0oIVL,
  plugin_h9wXJmkVyi,
  _000_breaker_client_xINncjSvz7,
  _001_qrId_check_client_JGBoz9pueN,
  _002_prod_beta_liff_client_BxqcQ7QM0G,
  _003_eventId_to_localstorage_client_8rkACoW05W,
  _008_wish_site_client_n8vfv7AW6P,
  _101_idle_to_reload_QQHZvxCeOh,
  i18n_VfGcjrvSkj,
  prefetch_templates_client_mgQLXdR3ui,
  prefetch_wish_client_vSB1aomzRh,
  sentry_client_shVUlIjFLk,
  trackingAnalytic_RY9P8l6vUU,
  trackingPage_UajUVRF1fx
]